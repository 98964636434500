<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增/修改 分类</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
          <div class="form-group">
            <label><b>排序号</b></label>
            <el-input type="text" v-model="model.SORT"/>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {}
    }
  },
  methods:{
    init(method,model){
      this.sta={show:true,loading:false}
      if (method==1) //增加根分类
        this.model={};
      else if (method==2) //增加下级分类
        this.model={PARENT_ID:model.ID};
      else //修改分类
        this.model=JSON.parse((JSON.stringify(model)));
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/MAT/MCatApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
