<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-shopping-cart-full"> </i> 物料管理 | 分类设置</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit(1)">新增根分类</el-button>
            <el-button type="success" @click="imp">Excel导入</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
            </div>
            <div class="w-block">
              <el-table :data="grid.ls" v-loading="grid.loading" row-key="ID" border :tree-props="{ children: 'CHILDREN' }"
                lazy :load="getList" :cell-style="{ padding: '0px' }" :row-style="gridRowStyle"
                :header-cell-style="{ borderBottom: 'solid 1px #ccc', backgroundColor: '#f5f5f5', textAlign: 'center', padding: '5px 0px' }">
                <el-table-column prop="FULL_NAME" label="分类全称" width="300" />
                <el-table-column prop="NAME" label="物品名称" width="200">
                  <template slot-scope="scope">
                    <span>{{ scope.row.NAME }}</span>
                    <span v-if="!scope.row.LEAF"> [{{ scope.row.CNT_SON }}]</span>
                  </template>
                </el-table-column>
                <el-table-column prop="SORT" label="排序" width="80" align="center" />
                <el-table-column prop="ID" label="ID" width="80" align="center" />
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-plus" @click="rowEdit(2, scope.row)">增加</el-button>
                    <el-button type="text" icon="el-icon-edit" @click="rowEdit(3, scope.row)">修改</el-button>
                    <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="cat_edit" @on-saved="filter" />
    </div>
    <div>
      <IMP ref="cat_imp" @on-saved="filter" />
    </div>
  </div>
</template>

<script>
import Edit from "./edit";
import IMP from './imp'
export default {
  name: "index",
  components: { Edit, IMP },
  data() {
    return {
      grid: {
        ls: [],
        loading: false
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    filter() {
      this.grid.ls = [];
      this.getList();
    },
    getList(tree, treeNode, resolve) {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/MAT/MCatApi/GetList",
        data: { PARENT_ID: tree == undefined ? 0 : tree.ID },
        completed: function (its) {
          its.forEach(o => { o.hasChildren = !o.LEAF })
          if (resolve != undefined) {
            resolve(its)
          }
          else
            self.grid.ls = its;
          self.grid.loading = false;
        }
      })
    },
    gridRowStyle(row) {
      let lay = row.row.LAYER;
      if (lay == 1) return { background: '#e7f3ff' }
      else if (lay == 2) return { background: '#fcfbed' }
      else return { background: '#f5f5f5' }
    },
    rowEdit(method, row) {
      this.$refs.cat_edit.init(method, row);
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        this.whale.remote.getResult({
          url: "/api/School/MAT/MCatApi/Delete",
          data: { ID: r.ID },
          completed: function () {
            location.reload()
          }
        })
      });
    },
    imp() {
      this.$refs.cat_imp.init();
    }
  }
}
</script>

